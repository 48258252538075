import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import { FaRegClock } from 'react-icons/fa'
import { IoMdArrowRoundForward } from 'react-icons/io'
import Banner from '../components/Banner'

const PaginatedTemplate = ({ pageContext }) => {
  const { group, index, first, last } = pageContext
  const previousIndex = index - 1
  const nextIndex = index + 1

  const previousPageUrl = previousIndex === 1 ? "/blog" : `/blog/${previousIndex}`
  const nextPageUrl = `/blog/${nextIndex}`

  return (
    <>
      <Layout>
        <Wrapper>
          <h1 className='page-heading'>Blog</h1>
          {group.map(({ node }) => (
            <div key={node.id} className="blogListing">
              <GatsbyImage image={getImage(node.frontmatter.image)} alt={node.title} className="img" />
              <div className="info">
                <h3>{node.frontmatter.title}</h3>
                <span className="category">{node.frontmatter.category}</span>
                <div className="underline"></div>
                <p>{node.excerpt}</p>
                <Link to={`/posts/${node.frontmatter.slug}`} className="link">
                  Continue Reading <IoMdArrowRoundForward />
                </Link>
                <footer>
                  <span className="date">
                    <FaRegClock className="icon" />
                    {node.frontmatter.date}
                  </span>
                  <span>{node.frontmatter.readTime} min read</span>
                </footer>
              </div>

            </div>
          ))}
          <div className='navbar'>
            {!first && (
              <Link to={previousPageUrl} className="previous-link">
                Previous page
              </Link>
            )}

            {!last && (
              <Link to={nextPageUrl} className="next-link">
                Next page
              </Link>
            )}
          </div>
        </Wrapper>
        <Banner />
        <br></br>
        <br></br>
      </Layout>
    </>
  )
}

const Wrapper = styled.section`
margin:3rem 2rem;
.page-heading{
font-size:2.5rem;
font-weight: 150;
margin-top:5rem;
margin-bottom:2rem;
font-family:var(--ff-tertiary);
}
.info {
  text-align: center;
  }
  .img {
    margin-bottom: 1rem auto;
    width: 100%;
    border-radius: var(--radius);
    height: 17rem;

  }
  .category {
    display: inline-block;
    margin-bottom: 1rem;
    background: var(--clr-grey-10);
    padding: 0.25rem 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: var(--radius);
    letter-spacing: var(--spacing);
    color: var(--clr-grey-5);
  }
  h3 {
    font-weight: 400;
    margin-bottom: 1rem;
    margin-top: 1rem;
    text-transform: initial;
  }
  .underline {
    width: 5rem;
    height: 1px;
    background: var(--clr-grey-9);
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  p {
    color: var(--clr-grey-5);
    line-height: 1.8;
    font-size: 1.3rem;
  }
  .link {
    text-transform: uppercase;
    letter-spacing: var(--spacing);
    font-weight: 700;
    color: var(--clr-primary-5);
    padding-bottom: 0.1rem;
    display: flex;
    align-items: center;
    svg {
      margin-left: 0.25rem;
      font-size: 1.2rem;
    }
  }
  .link:hover {
    border-color: var(--clr-primary-8);
    color: var(--clr-primary-8);
  }
  footer {
    margin-top: 2rem;
    margin-bottom: 4rem;
    padding-top: 1rem;
    border-top: 1px solid var(--clr-grey-9);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--clr-grey-5);

    & .date {
      display: flex;
      align-items: center;
      & .icon {
        color: var(--clr-primary-5);
        margin-right: 0.5rem;
      }
    }
  }
  @media (min-width: 600px) {
    .img {
      height: 20rem;
    }
  }
  @media (min-width: 800px) {
    .img {
      height: 25rem;
    }
  }
  @media (min-width: 992px) {
    & {
width: 45rem;
margin:5rem auto;
      display: grid;
      column-gap: 1.5rem;
      row-gap: 2.5rem;
      .info {
        text-align: left;
      }
      .img {
        height: 100%;
        max-height: 20rem;
      }
      .underline {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .navbar{
  width: 20rem;
  margin: 2rem auto;
  padding: 0 2rem;
  height:50px;
  display: flex;
  justify-content: space-between;
  border: solid 1px var(--clr-grey-5)
  }
  .next-link, .previous-link, {
    font-size: 1.2rem;
    }
`

export default PaginatedTemplate